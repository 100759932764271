// src/components/PrivateRoute.js
import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useAuth0 } from './Auth'

// @ts-ignore
export const SecureRoute = ({ component: Component, path, ...rest }) => {
    // @ts-ignore
    const auth = useAuth0()

    useEffect(() => {
        if ((auth && auth.isLoading) || (auth && auth.isAuthenticated)) {
            return
        }
        const fn = async () => {
            if (auth) {
                await auth!.loginWithRedirect({
                    appState: { targetUrl: window.location.pathname },
                })
            }
        }
        if (auth && auth.isLoading) {
            fn()
        }
    }, [auth])

    const render = (props: any) => (auth && auth.isAuthenticated === true ? <Component {...props} /> : null)

    return <Route path={path} render={render} {...rest} />
}
