import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client, { Auth0Client, IdToken } from '@auth0/auth0-spa-js'

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)
type _Auth0Context = {
    isAuthenticated: boolean | undefined
    user: undefined
    isLoading: boolean
    isPopupOpen: boolean
    loginWithPopup: (params?: {}) => Promise<void>
    handleRedirectCallback: () => Promise<void>
    getIdTokenClaims: (...p: any[]) => Promise<IdToken>
    loginWithRedirect: (...p: any[]) => Promise<void>
    getTokenSilently: (...p: any[]) => Promise<any>
    getTokenWithPopup: (...p: any[]) => Promise<any>
    logout: (...p: any[]) => void
}

export const Auth0Context = React.createContext<_Auth0Context | null>(null)

export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({
    children,
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    domain,
    clientId,
    redirectUri,
    audience,
}: {
    children: any
    onRedirectCallback?: (appState: any) => void
    domain: string
    clientId: string
    redirectUri: string
    audience: string
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>()
    const [user, setUser] = useState()
    const [auth0Client, setAuth0Client] = useState<Auth0Client>()
    const [isLoading, setIsLoading] = useState(true)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    console.info({ isAuthenticated, isLoading, auth0Client })
    useEffect(() => {
        const initAuth0 = async () => {
            console.info('initialising auth0 client')
            const auth0FromHook = await createAuth0Client({
                domain,
                client_id: clientId,
                redirect_uri: redirectUri,
                useRefereshTokens: true,
                audience,
                advancedOptions: {
                    defaultScope: 'email',
                },
            })
            setAuth0Client(auth0FromHook)
            console.info({ auth0FromHook })

            if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
                const { appState } = await auth0FromHook.handleRedirectCallback()
                onRedirectCallback(appState)
            }

            const isAuthenticated = await auth0FromHook.isAuthenticated()
            setIsAuthenticated(isAuthenticated)

            if (isAuthenticated) {
                const user = await auth0FromHook.getUser()
                setUser(user)
            }

            setIsLoading(false)
        }
        try {
            initAuth0()
        } catch (e) {
            console.error(e)
        }
        // eslint-disable-next-line
    }, [])

    const loginWithPopup = async (params = {}) => {
        if (!auth0Client) return
        setIsPopupOpen(true)

        try {
            await auth0Client.loginWithPopup(params)
        } catch (error) {
            console.error(error)
        } finally {
            setIsPopupOpen(true)
        }

        const user = await auth0Client.getUser()
        setUser(user)
        setIsAuthenticated(true)
    }

    const handleRedirectCallback = async () => {
        if (!auth0Client) return

        setIsLoading(true)
        await auth0Client.handleRedirectCallback()
        const user = await auth0Client.getUser()
        setIsLoading(false)
        setIsAuthenticated(true)
        setUser(user)
    }

    const value = {
        isAuthenticated,
        user,
        isLoading,
        isPopupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p: any[]) => auth0Client!.getIdTokenClaims(...p),
        loginWithRedirect: (...p: any[]) => auth0Client!.loginWithRedirect(...p),
        getTokenSilently: (...p: any[]) => auth0Client!.getTokenSilently(...p),
        getTokenWithPopup: (...p: any[]) => auth0Client!.getTokenWithPopup(...p),
        logout: (...p: any[]) => auth0Client!.logout(...p),
    }
    return <Auth0Context.Provider value={value}>{children}</Auth0Context.Provider>
}
