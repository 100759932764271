import React, { useState, useEffect } from 'react'
import BuyerUpLogo from '../../assets/images/buyerup-logo.svg'
import { Link } from 'react-router-dom'
import { useAuth0 } from '../../Auth'
import { getConfig } from '../../config'
import './NavBar.scss'

type Agency = {
    name: string
    logo: string
}

type Agent = {
    firstName: string
    lastName: string
    agency: Agency
}

type AgentSearchApiResponse = {
    agents: Agent[]
}

const createQueryParams = (params: any) =>
    Object.keys(params)
        .map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        })
        .join('&')


const getAgents = async (
    email: string,
    auth: any,
    setLogo: any
) => {

    const { api } = getConfig(window.location)

    const handleErrorResponse = (response: Response) => {
        if ((response.status >= 400 && response.status < 600) || !response.ok) {
            throw new Error('Bad response from server')
        } else return response.json()
    }

    try {
        const token = await auth!.getTokenSilently()
        if (
            email
        ) {
            const response = await fetch(
                `${api}/agents?${createQueryParams({
                    email
                })}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            ).then(handleErrorResponse)

            setLogo(response.agents[0].agency.logo);

        } else {
            throw new Error('Add all the values in')
        }
    } catch (e) {
        console.error(e)
        throw e
    }
}



export const NavBar = () => {
    // @ts-ignore
    const { isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0()
    const { auth0 } = getConfig(window.location)
    const auth = useAuth0();

    const [logo, setLogo] = useState<string>('');

    if (auth?.isAuthenticated && auth.user) {
        getAgents((auth.user || {email: ''}).email, auth, setLogo)
    }

    useEffect(()=>{
        if (isLoading === false && isAuthenticated === false) {
            loginWithRedirect({});
        }
    });

    return (
        <div id="nav-bar">
            <>
                <img src={ BuyerUpLogo } alt='logo' />

                {window.location.origin !== 'https://buyerup.com.au' && (
                    <>
                        {!isAuthenticated && (
                            <a href="" onClick={() => loginWithRedirect({})}>Log in</a>
                        )}

                        {isAuthenticated && (
                            <div className="dropdown">
                                <img src={ logo } />
                                <div className="dropdown-content">
                                    <>
                                        <a href="" onClick={() => logout({ returnTo: auth0.returnTo })}>Log out</a>
                                        {!window.location.pathname.includes('dashboard') && (
                                            <Link to='/dashboard' className=''>Dashboard</Link>
                                        )}
                                    </>
                                </div>
                            </div>
                        )}
                    </>
                )}

                {window.location.origin === 'https://buyerup.com.au' && (
                    { /*
                    <div className={NavBarLinkStyles} onClick={() => { window.location.href = 'https://app.buyerup.com.au'}}>
                        Log in
                    </div>
                    */}
                )}
            </>
        </div>
    )
}
