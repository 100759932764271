import React from 'react'
import Loading from '../../assets/images/buyerup-logo.svg'
import './Loader.scss'

export const Loader = () => (
    <div className='loading-screen'>
        <img src={Loading} alt='Loading...' />
        <div className='spinner'>
            <div className='bounce1'></div>
            <div className='bounce2'></div>
            <div className='bounce3'></div>
        </div>
    </div>
);
