import React, { Suspense, lazy } from 'react'
import './App.scss'
import { NavBar } from './components/NavBar/NavBar'
import 'semantic-ui-css/semantic.min.css'
import { Router, Route, Switch } from 'react-router-dom'
import { Loader } from './components/Loader/Loader'
import { Home } from './Home'

import { SecureRoute } from './SecureRoute'
import { useAuth0, Auth0Provider } from './Auth'
import { createBrowserHistory } from 'history'
import { getConfig } from './config'

const Search = lazy(() => import('./Search'))

const LoadingScreen = () => (
    <Loader />
)
const { auth0 } = getConfig(window.location)

const Routes: React.FC = () => {
    const authContext = useAuth0()
    const history = createBrowserHistory()

    if (authContext && authContext.isLoading) return <LoadingScreen />
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Router history={history}>
                <Auth0Provider
                    domain={auth0.domain}
                    clientId={auth0.clientId}
                    redirectUri={auth0.redirectUri}
                    audience={auth0.audience}
                >
                    <div>
                        <header>
                            <NavBar />
                        </header>{' '}
                        <Switch>
                            { /*<Route path='/' exact component={Home} /> */}
                            <SecureRoute path='/dashboard' component={Search} />
                        </Switch>
                    </div>
                </Auth0Provider>
            </Router>
        </Suspense>
    )
}

export default Routes
