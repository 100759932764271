type ConfigValues = {
    auth0: {
        domain: string
        clientId: string
        audience: string
        redirectUri: string
        returnTo: string
    }
    api: string
}
type Config = {
    development: ConfigValues
    staging: ConfigValues
    production: ConfigValues
    production_homepage: ConfigValues
}
enum Origin {
    development = 'http://localhost:3000',
    staging = 'https://staging.app.buyerup.com.au',
    production = 'https://app.buyerup.com.au',
    production_homepage = 'https://buyerup.com.au'
}
const config: Config = {
    development: {
        auth0: {
            domain: 'development-buyerup.au.auth0.com',
            clientId: 'Q0sqMGouMPbwU2VBSFuhfXlVmIUO5BbM',
            audience: 'https://development-buyerup.com.au',
            redirectUri: 'http://localhost:3000/dashboard',
            returnTo: 'http://localhost:3000'
        },
        api: 'http://localhost:8080',
    },
    staging: {
        auth0: {
            domain: 'staging-buyerup.au.auth0.com',
            clientId: 'RifKimazUg3mB1rPEXusAHYEHyNdsA51',
            audience: 'https://staging.api.buyerup.com.au',
            redirectUri: 'https://staging.app.buyerup.com.au/dashboard',
            returnTo: 'https://staging.app.buyerup.com.au'
        },
        api: 'https://staging.api.buyerup.com.au',
    },
    production: {
        auth0: {
            domain: 'buyerup.au.auth0.com',
            clientId: 'Pjm2b05KTo5hxzCzEz9s7HSqMkx5WC8Z',
            audience: 'https://api.buyerup.com.au',
            redirectUri: 'https://app.buyerup.com.au/dashboard',
            returnTo: 'https://app.buyerup.com.au'
        },
        api: 'https://api.buyerup.com.au',
    },
    production_homepage: {
        auth0: {
            domain: 'buyerup.au.auth0.com',
            clientId: 'Pjm2b05KTo5hxzCzEz9s7HSqMkx5WC8Z',
            audience: 'https://api.buyerup.com.au',
            redirectUri: 'https://buyerup.com.au/dashboard',
            returnTo: 'https://buyerup.com.au'
        },
        api: 'https://api.buyerup.com.au',
    },
}

export const getConfig = (location: Location): ConfigValues => {
    switch (location.origin) {
        case Origin.development:
            return config['development']

        case Origin.staging:
            return config['staging']

        case Origin.production:
            return config['production']

        case Origin.production_homepage:
            return config['production_homepage']

        default:
            throw new Error(`Couldn't find the right config values origin=${location.origin}`)
    }
}
